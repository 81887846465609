import Dynamic from 'next/dynamic';
import Head from 'next/head';
import style from './layout.module.scss';

const Navigation = Dynamic(() => import('./Navigation.js'));
const Footer = Dynamic(() => import('./Footer.js'));

function Layout ({children, ...pageProps}) {

  return (
    <div className={style.Layout}>
      <Head></Head>
      <Navigation windowSize={pageProps.windowSize} webp={pageProps.webp}/>
        {children}
      <Footer/>
    </div>
  )
}

export default Layout