import './App.scss'
import Head from 'next/head'
import { useRouter } from 'next/router';
import Layout from '../components/layout/Layout'
import * as ga from '../components/functions/GAEvents'
import React, { useState, useEffect } from 'react';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { fab, faFacebookSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { fal, faInfoCircle, faBars, faTimes, faChevronDown, faChevronUp, faCaretDown, faCopyright, faMapMarkerAlt, faChevronLeft, faExclamationCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { far, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons'

config.autoAddCss = false
library.add(fab, fal, far, faInfoCircle, faFacebookSquare, faLinkedin, faYoutubeSquare, faBars, faTimes, faChevronDown, faChevronUp, faCaretDown, faLongArrowRight, faCopyright, faMapMarkerAlt, faChevronLeft, faExclamationCircle, faCheckCircle)


function MyApp({ Component, pageProps }) {

  //Set the window size state to maintain media queries
  const [windowSize, setWindowSize] = useState('Mobile')
  
  useEffect (() => {
		const handleResize = () => {
			let size = ''
			if (window.matchMedia("(min-width: 950px)").matches) {
				size = 'Web'
			} else if (window.matchMedia("(min-width: 500px)").matches) {
				size = 'Tablet'
			} else {
				size = 'Mobile'
			}

			if (size !== windowSize) {
				setWindowSize(size)
			}
		}
		
		window.addEventListener('resize', handleResize)

		handleResize()

		return () => {
			window.removeEventListener('resize', handleResize)
		}
  }) 


	//Hendles checking for the supported image format and passes it to all pages
	const [webp, setWebp] = useState("webp")

	useEffect (() => {
		let kTestImages = {
				lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
				lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
				alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
				animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
		};
		let img = new Image();
		img.onload = function () {
				let result = (img.width > 0) && (img.height > 0);
				if (result) {
					setWebp("webp")
				} else {
					setWebp("nowebp")
				}
		};
		img.onerror = function () {
			setWebp("nowebp")
		};
		img.src = "data:image/webp;base64," + kTestImages["lossy"];
  }) 

	//Handler for page changes since GA would not be able to track pageviews otherwise
	const router = useRouter();

  const handleRouteChange = (url) => {
		//Send a pageview event to GA
		ga.pageview(url)
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			<Layout webp={webp} windowSize={windowSize}>
				<Component {...pageProps} webp={webp} windowSize={windowSize}/> 
			</Layout>
		</>
  ) 
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp