//Google Analytics Functions

//Pageview Handler
export const pageview = (url) => {
  if (document.location.hostname.indexOf("www.baslerservices.com") > -1) {
    window.gtag('config', `${process.env.NEXT_PUBLIC_GA_TRACKING}`, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: url,
      send_page_view: true
    });
  }
}

//Event Handler
export const event = (action, params) => {
  if (document.location.hostname.indexOf("www.baslerservices.com") > -1) {
    //check if there are any params passed to the handler
    if (params !== null && params !== undefined) {
      window.gtag('event', action, params);
    } else {
      window.gtag('event', action);
    }
  }
}